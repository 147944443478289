import qs from 'qs'

const fields = [
  'slug'
]

export const queryBienniaParticipantEntriesCount = (maxBiennialEntryEditionIndex: number) => {
  const query = qs.stringify(
    {
      publicationState: 'live',
      pagination: {
        page: 1,
        pageSize: 1
      },
      filters: {
        biennialEntries: {
          editionIndex: {
            $lte: maxBiennialEntryEditionIndex
          }
        }
      },
      fields
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-participant-entries?${query}`
}
