<template>
  <section :class="$style.root">
    <SharedNewsletterComponent />
  </section>
</template>

<style module>
.root {
  composes: container from global;
}

.content {
}

.label {
  margin-top: var(--padding--element--top);
}
</style>
