<template>
  <div>
    <PagesIndexComponent
      v-if="dataBiennials?.data?.length"
      :data="data"
      :biennials-data="dataBiennials"
      :biennials-selected-data="selectedData"
      :biennial-page-data="dataBiennialPage"
      :biennial-participant-entries-count="dataBiennialParticipantEntries?.meta?.pagination?.total"
    />

    <NuxtPage />
  </div>
</template>

<script lang="ts" setup>
// 1
const queryPath = queryHomePage()
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPath } }
  )
})

basePageSetHead(data)

// 2
const queryPathBiennials = queryHomePageBiennialEntriesThumbnail({
  page: 1,
  pageSize: 100
})
const { data: dataBiennials } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPathBiennials } }
  )
})

// 3
const { data: selectedData } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryBiennialThumbnailSelected() } }
  )
})

// 4
const { data: dataBiennialPage } = await useLazyFetch('/api/strapi-rest', { query: { path: queryBiennialPage() } })

// 5
const manuallySelectedEditionIndex = selectedData?.value?.data?.attributes?.lastBiennialEntry?.data?.attributes?.editionIndex
const { data: dataBiennialParticipantEntries } = await useLazyFetch('/api/strapi-rest', { query: { path: queryBienniaParticipantEntriesCount(manuallySelectedEditionIndex) } })
</script>
