import qs from 'qs'

const fields = [
  'title',
  'richText'
]

export const queryHomePage = () => {
  const query = qs.stringify(
    {
      fields,
      populate: {
        ...queryFragmentSeo,
        links: {
          ...queryFragmentLink,
        },
        // NOTE extract
        items: {
          fields: ['richText'],
          populate: {
            link: {
              ...queryFragmentLink,
            },
            image: {
              populate: '*'
            },
            muxAsset: {
              populate: '*'
            },
            biennialEntry: {
              fields: [
                'title',
                'slug',
                'dateStart',
                'dateEnd'
              ],
              populate: {
                colors: true
              }
            },
          }
        },
        newsEntries: {
          sort: ['datePosted:desc', 'title:asc'],
          fields: [
            'title',
            'slug',
            'datePosted'
          ],
          populate: {
            ...queryFragmentBiennialEntriesThumbnail,
            blocks: {
              on: {
                ...queryFragmentFirstRichTextBlock
              }
            },
            coverImage: '*'
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
