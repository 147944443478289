<template>
  <div
    v-if="page"
    :class="$style.root"
  >
    <PagesIndexAboutComponent
      :rich-text="page?.richText"
      :links="page?.links"
      :entries="biennialsData?.data"
      :biennials-selected-data="biennialsSelectedData"
      :biennial-participant-entries-count="biennialParticipantEntriesCount"
      :class="$style.section"
    />

    <section :class="$style.section">
      <ul :class="$style.items">
        <li
          v-for="(item, index) in entries"
          :key="item?.id || index"
          :data-type="item?.type"
          :class="$style.item"
        >
          <SharedNewsThumbnailComponent
            v-if="item?.type === 'news'"
            :entry="attributes(item)"
          />

          <SharedBiennialThumbnailComponent
            v-else-if="item?.type === 'biennial'"
            :entry="item"
            :is-current="item?.id === selectedBiennialId"
          />

          <PagesIndexItemComponent
            v-else-if="item?.type === 'item'"
            :entry="item"
            :prevEntry="entries[index - 1] || null"
          />

          <PagesIndexNewsletterComponent v-else-if="item?.type === 'newsletter'" />
        </li>
      </ul>
    </section>

    <SharedLogoComponent :class="$style.logo" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object
  },
  biennialsData: {
    type: Object,
    required: false
  },
  biennialsSelectedData: {
    type: Object,
    required: false
  },
  biennialPageData: {
    type: Object,
    required: false
  },
  biennialParticipantEntriesCount: {
    type: Number,
    required: false
  }
})

const page = baseStrapiGetAttributes(props.data)
const attributes = baseStrapiGetAttributes

const selectedBiennialId = props?.biennialsSelectedData?.data?.attributes?.currentBiennialEntry?.data?.id

// NOTE filter out items that are doubled by items
const biennials = props?.biennialsData?.data.map((i) => {
  return {
    ...i,
    type: 'biennial'
  }
}) || []

const news = page?.newsEntries?.data.map((i) => {
  return {
    ...i,
    type: 'news'
  }
}) || []

// NOTE filter out items with no link, image, relation or …
const items = page?.items?.map((i) => {
  return {
    ...i,
    type: 'item'
  }
})?.filter((i => {
  return i?.link?.id || i?.image?.data?.id || i?.muxAsset?.data?.id
}))?.filter(Boolean) || []

const allItems = indexGetAllItems(biennials, news, items, selectedBiennialId)
const entries = ref([])

allItems?.forEach((item, index) => {
  if (item?.type === 'biennial') {
    entries.value.push(indexBiennialsItem(item, index, entries.value))
  } else {
    entries.value.push(item)
  }
})
</script>

<style module lang="scss">
.root {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;

  min-height: var(--100svh);
}

.section {
  flex: 1;
}

.section:not(:last-child) {
  margin-bottom: calc(var(--font-default--line-height) * 2);
}

.items {
  composes: reset-list container-index-page from global;
}

.item {
}

.item[data-type="newsletter"] {
  width: 100%;
}
</style>
