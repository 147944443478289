export const indexBiennialsItem = (biennial: object, index: number = 0, entries: array = []) =>  {
  const prevItemIsBiennial = entries[index - 1]?.type === 'biennial'
  const attributes = useOmit(biennial?.attributes, ['biennialConcept'])

  const prevImage = entries?.find((item, i) => {
    return item?.type === 'biennial' &&
      item?.editionIndex === (attributes?.editionIndex + 1) &&
      item?.image?.id
  })
  const prevImageOrientation = prevImage?.image?.orientation

  const firstTextBlock = biennial?.attributes?.biennialConcept?.data?.attributes?.blocks?.find(i => i?.__component === 'blocks.rich-text')?.richText
  const images = useFlattenDeep(
    biennial?.attributes?.biennialConcept?.data?.attributes?.blocks?.map((block) => {
      return block?.files?.data?.map((image) => {
        return {
          ...image,
          richText: baseTruncateString(firstTextBlock, 28)
          // richText: block?.richText
        }
      })?.filter((i) => {
        return i?.attributes?.hideFromHomepage ? i?.attributes?.hideFromHomepage !== true : true
      })
    })
  )?.filter(image => image && imageSizeCheck(image?.attributes))

  const image = prevItemIsBiennial ? useShuffle(images)?.find((image) => {
    const orientation = getImageOrientation(image)
    return orientation !== prevImageOrientation
  }) || useSample(images) : useSample(images)

  const finalImage = {
    ...image,
    orientation: getImageOrientation(image)
  }

  return {
    id: biennial?.id,
    ...attributes,
    image: finalImage,
    type: 'biennial'
  }
}
