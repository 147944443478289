const sortAndMergeByDate = (biennials: Array<any> = [], news: Array<any> = [], items: Array<any> = []) =>  {
  const entries = [...news, ...biennials, ...items]
  // NOTE bit of a hack to get the order of the entries correct: [biennial, item, news]
  const entriesOrdered = useOrderBy(entries, ['type'], ['desc'])
  const entriesChronologically = entriesOrdered?.sort((a, b) => {
    const aDate = (a?.attributes?.dateEnd || a?.biennialEntry?.data?.attributes?.dateEnd || a?.attributes?.datePosted)?.replace(/-/g, '')
    const bDate = (b?.attributes?.dateEnd || b?.biennialEntry?.data?.attributes?.dateEnd || b?.attributes?.datePosted)?.replace(/-/g, '')
    return aDate - bDate
  })?.reverse()

  return entriesChronologically
}

export const indexGetAllItems = (biennials: Array<any> = [], news: Array<any> = [], items: Array<any> = [], selectedBiennialId: number, newsletterIndex: number = 4) => {
  // 1
  const itemsWithRelationToSelectedBiennial = items?.filter((i) => i?.biennialEntry?.data?.id && i?.biennialEntry?.data?.id === selectedBiennialId)
  const newsWithRelationToSelectedBiennial = news?.filter((n) => {
    return n?.attributes?.biennialEntries?.data?.some((b) => b?.id === selectedBiennialId)
  })
  const selectedBiennial = biennials?.filter((b) => b?.id === selectedBiennialId)
  const itemsForSelectedBiennial = sortAndMergeByDate(selectedBiennial, newsWithRelationToSelectedBiennial, itemsWithRelationToSelectedBiennial)

  // 2
  const itemsWithRelationToNonSelectedBiennial = items?.filter((i) => i?.biennialEntry?.data?.id && i?.biennialEntry?.data?.id !== selectedBiennialId)
  const newsWithoutRelationToSelectedBiennial = news?.filter((n) => {
    return n?.attributes?.biennialEntries?.data?.length === 0 || n?.attributes?.biennialEntries?.data?.some((b) => b?.id !== selectedBiennialId)
  })
  const nonSelectedBiennials = biennials?.filter((b) => b?.id !== selectedBiennialId)
  const defaultItems = sortAndMergeByDate(nonSelectedBiennials, newsWithoutRelationToSelectedBiennial, itemsWithRelationToNonSelectedBiennial)

  // 3
  const itemsWithRandomOrder = items?.filter((i) => !i?.biennialEntry?.data?.id)

  // 4
  const allItems = [...itemsForSelectedBiennial, ...defaultItems]
  const allItemsLength = allItems?.length
  const itemsWithRandomOrderLength = itemsWithRandomOrder?.length
  const newsletterLength = 1
  const count = allItemsLength + itemsWithRandomOrderLength + newsletterLength

  const range = useRange(count)
  const rangeForRandomInserts = useRange(newsletterIndex, (range?.length - 1))

  const randomInsertPositionsSteps = Math.floor(rangeForRandomInserts?.length / itemsWithRandomOrderLength)
  const insertIndexes = [newsletterIndex, ...useRange(itemsWithRandomOrderLength)?.map(i => newsletterIndex + ((i + 1) * randomInsertPositionsSteps))]

  const newsLetterObject = {
    type: 'newsletter'
  }

  const itemsSorted = range?.map((i) => {
    if (i === newsletterIndex) {
      return newsLetterObject
    } else if (insertIndexes.includes(i)) {
      const item = itemsWithRandomOrder?.shift()
      return item
    } else {
      const item = allItems?.shift()
      return item
    }
  })

  return itemsSorted
}
