<template>
  <div
    ref="element"
    :class="$style.root"
    :data-sticky="sticky ? '' : null"
  >
    <svg
      :class="$style.svg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 263.7 35"
      xml:space="preserve"
    >
      <rect
        x="124"
        y="0"
        width="4.5"
        height="35"
        @click="sticky = false"
      />
      <polygon
        points="133.3,0 154.3,0 154.3,3.9 137.8,3.9 137.8,15.8 151.8,15.8 151.8,19.6 137.8,19.6 137.8,35 133.3,35"
        @click="sticky = false"
      />
      <polygon
        points="157.9,0 179.7,0 179.7,3.9 162.4,3.9 162.4,15.3 177.9,15.3 177.9,19.2 162.4,19.2 162.4,31.1 180,31.1 180,35 157.9,35"
        @click="sticky = false"
      />
      <path
        d="M183.3,26.6l4.2-1.3c1.5,4.4,4.8,6.6,9.3,6.6c5.1,0,8.5-2.1,8.5-6.3c0-2.9-1.6-4.8-7.1-6l-3.9-0.9 c-4.9-1.1-9.1-3.5-9.1-9.3c0-5.4,4.4-9.8,11.6-9.8c7.5,0,10.7,3.4,12.5,7.6l-3.9,1.6c-1.6-3.7-4-5.5-8.5-5.5 c-5.1,0-7.1,2.7-7.1,5.6c0,3.5,2.5,4.8,6.8,5.8l4.4,1c6.5,1.5,9,4.7,9,9c0,6.6-5.1,10.9-13.2,10.9 C189.2,35.7,184.6,31.2,183.3,26.6"
        @click="sticky = false"
      />
      <polygon
        points="211.5,0 237.8,0 237.8,3.9 226.9,3.9 226.9,35 222.4,35 222.4,3.9 211.5,3.9"
        @click="sticky = false"
      />
      <path
        d="M246.9,7.7c0.7-1.9,1.2-3.7,1.2-3.7h0.1c0,0,0.5,1.9,1.2,3.8l4.7,13.4h-12L246.9,7.7z M258.9,35h4.8L250.9,0h-5.4 l-12.7,35h4.4l3.6-10.1h14.6L258.9,35z"
        @click="sticky = false"
      />
      <path
        d="M114.9,0v28.9h-0.1c0,0-0.7-1.6-1.4-2.8L98.1,0h-5.3v28.9L63.9,0l-29,29v-29h-6.6l-9.5,26.6c-0.6,1.5-1.1,3.5-1.1,3.5 h-0.1c0,0-0.6-2.1-1.1-3.5L7,0.1H0v35h4.3V5.1h0.2c0,0,0.2,1.2,0.8,2.7l9.8,27.2h4.8l9.8-27.2c0.6-1.6,0.8-2.7,0.8-2.7h0.2v29.9 h4.3v0l29-29L92.9,35v0h4.3V6.1h0.1c0,0,0.7,1.6,1.4,2.8L113.9,35h5.3V0H114.9z"
        @click="sticky = false"
      />
    </svg>
  </div>
</template>

<script setup>
const mainStore = useMainStore()
const stickyPosition = computed(() => mainStore?.indexLogoLightboxSticky)

const element = ref(null)
const { y } = useWindowScroll()
const sticky = ref(stickyPosition.value)
const scrolledOnce = ref(false)

watch(y, (value) => {
  const windowHeight = window.innerHeight
  const elementHeight = Math.round(element?.value?.getBoundingClientRect()?.height)
  const elementTop = Math.round(element?.value?.getBoundingClientRect()?.top)
  const elPos = elementTop + elementHeight
  if (elPos < windowHeight) {
    if (scrolledOnce.value !== true) {
      scrolledOnce.value = true
      sticky.value = false
    }
  }
})

watch(sticky, (value) => {
  if (!value) {
    mainStore.setUserActionHasToggledIndexPageLogo()
  }
})
</script>

<style module lang="scss">
.root {
  pointer-events: none;

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.root[data-sticky] {
  --bottom: var(--font-small--line-height);
  position: sticky;
  bottom: 0;
  padding-bottom: var(--bottom);
  margin-bottom: calc(var(--bottom) * -1);
  z-index: 1;
}

.svg {
  overflow: visible;
  fill: var(--color--primary);
}

.root[data-sticky] .svg * {
  pointer-events: all;
  cursor: pointer;
}
</style>
