<template>
  <LayoutAspectRatioComponent
    :class="$style.root"
    :data-orientation="orientation ? orientation : null"
    :data-has-relation="entryType === 'link' && entry?.biennialEntry?.data?.attributes ? '' : null"
    :data-link="entryType === 'link' ? '' : null"
  >
    <CoreBlocksLinksLinkComponent
      v-if="entry?.link"
      :link="entry?.link"
      :class="$style.link"
    />

    <div
      v-else-if="entry?.muxAsset?.data"
      :class="$style.video"
    >
      <client-only>
        <CoreMuxVideoComponent
          :video="entry?.muxAsset?.data"
          :includeZoomButton="true"
        />
      </client-only>
    </div>

    <BaseStrapiImageComponent
      v-else-if="entry?.image?.data"
      :image="entry?.image?.data?.attributes"
    />

    <CoreTextComponent
      v-if="entry?.richText"
      :string="entry?.richText"
      :class="$style.richText"
      :style="colorTextDecoration"
    />

    <SharedBiennialRelationComponent
      :class="$style.relation"
      :page="entry"
      :style="colorTextDecoration"
    />
  </LayoutAspectRatioComponent>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object,
    required: true
  },
  prevEntry: {
    type: Object,
    required: false
  }
})

const entryType = computed(() => {
  if (props?.entry?.link) return 'link'
  if (props?.entry?.muxAsset?.data) return 'video'
  if (props?.entry?.image?.data) return 'image'
  return null
})

const relation = props?.entry?.biennialEntry?.data?.attributes

const colors = relation?.colors
const colorTextDecoration = colors?.backgroundColor && colors?.useBackgroundColor ? `--color--text-decoration: ${colors?.backgroundColor};` : ''

const orientation = getImageOrientation(props?.entry?.image?.data) || getMuxOrientation(props?.entry?.muxAsset?.data)?.orientation
</script>


<style module>
.root {
  display: inline-block;
}

.root * {
  text-decoration-color: var(--color--text-decoration);
}

.link {
  composes: font-size-large from global;
  --blocks--links-link--padding: var(--padding--button--large);
}

.relation {
  padding-left: var(--unit--horizontal);
  padding-right: var(--unit--horizontal);
}

.richText {
  padding-left: var(--unit--horizontal);
  padding-right: var(--unit--horizontal);

  color: var(--color--secondary)
}

.richText * {
  --color--secondary: var(--color--primary);
}

.root:has(.richText):has(.relation) .richText {
  margin-top: var(--padding--element--top);
}

.root:has(.relation):not(:has(.richText)) .relation {
  margin-top: var(--padding--element--top);
}
</style>
