<template>
  <section
    v-if="richText || links?.length"
    :class="$style.root"
  >
    <CoreTextComponent
      v-if="richText"
      :class="$style.introduction"
      :string="richText"
    />

    <CoreBlocksLinksComponent
      v-if="links?.length"
      :links="links"
      :class="$style.links"
    />

    <div
      :class="$style.summary"
      v-html="$t('pages.index.biennials.summary', { editionCount: entriesFiltered?.length, yearsCount: yearsCount, participantsCount: biennialParticipantEntriesCount })"
    />
  </section>
</template>

<script lang="ts" setup>
import { max, min } from 'lodash-es'

const props = defineProps({
  richText: {
    type: String,
    required: false
  },
  links: {
    type: Array,
    required: false
  },
  entries: {
    type: Array
  },
  biennialParticipantEntriesCount: {
    type: Number,
    required: false
  },
  biennialsSelectedData: {
    type: Object,
    required: false
  },
})

const entriesFiltered = computed(() => {
  const manuallySelectedEditionIndex = props?.biennialsSelectedData?.data?.attributes?.lastBiennialEntry?.data?.attributes?.editionIndex
  if (!manuallySelectedEditionIndex) return props?.entries
  return props?.entries?.filter(i => i?.attributes?.editionIndex <= manuallySelectedEditionIndex) || []
})

const yearsCount = computed(() => {
  const years = getBiennialsNavigationGroups(entriesFiltered.value)?.years?.map(i => i?.year) || []
  const minYear = min(years)
  const maxYear = max(years)
  return maxYear - minYear > 0 ? maxYear - minYear : undefined
})
</script>

<style module>
.root {

}

.introduction {
  composes: container from global;
}

.summary {
  composes: container caption font-size-small from global;
  margin-top: var(--font-default--line-height);
}

.links {
  composes: container from global;
  margin-bottom: 0;
  margin-top: var(--font-default--line-height) !important;
}
</style>
